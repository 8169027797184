import { Avatar, Box, Card, CardContent, CardMedia, Stack, Typography, alpha } from '@mui/material';
import { isFinite, sumBy } from 'lodash';
import posthog from 'posthog-js';
import { useState } from 'react';
import Iconify from 'src/components/iconify';
import { useRouter } from 'src/routes/hooks';
import { bgGradient } from 'src/theme/css';

import { IEvent, TicketSetting } from '../../types';
import { getPictureToDisplay } from '../../utils/picture';
import EventStatus from '../event/event-status';

interface Props {
  event: IEvent;
}
export const ALMOST_SOLD_OUT = 10;

export function getTotalTicketLeftPercentage(ticketSettings: TicketSetting[], id: number): number {
  const totalQuantity = sumBy(ticketSettings, 'quantity');
  const totalQuantityLeft = sumBy(ticketSettings, 'quantityLeft');

  if (id === 4601) {
    return 5;
  }

  if (totalQuantity === 0) {
    return 30;
  }

  const percentageLeft = (totalQuantityLeft / totalQuantity) * 100;
  return isFinite(percentageLeft) ? percentageLeft : 0;
}

export default function EventCard({ event }: Props) {
  const [openInfo, setOpenInfo] = useState(false);
  function formatDate(inputDate: Date) {
    const date = new Date(inputDate);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });

    return { date: day, month: month.toUpperCase() };
  }
  const router = useRouter();
  const { date, month } = formatDate(event?.startDate ?? event.date);
  const onClickEvent = () => {
    router.push(`/event/${event.id}`);
    posthog.capture('event_page_opened', { eventId: event.id });
  };

  const renderAvatar = (
    <Stack direction="row" spacing={-2}>
      {event.participants.slice(0, 3).map((participant, index) => (
        <Avatar
          key={index}
          src={getPictureToDisplay(participant)}
          sx={{ width: 35, height: 35, ml: -0.5, zIndex: index + 1 }}
        />
      ))}
      {event?.eventParticipantsCount > 3 && (
        <Avatar
          sx={{
            width: 35,
            height: 35,
            ml: -0.5,
            zIndex: 4,
            backgroundColor: '#000',
            color: 'white',
            fontSize: '13px',
          }}
        >
          +{(event?.eventParticipantsCount ?? 0) - 3}
        </Avatar>
      )}
    </Stack>
  );

  return (
    <Card
      onClick={() => {
        if (!event?.configuration || event?.configuration?.isVisible) {
          onClickEvent();
        } else {
          setOpenInfo((prev) => !prev);
        }
      }}
      key={event.id}
      sx={{
        position: 'relative',
        minHeight: '45vh',
        height: '45vh',
        borderRadius: '6px',
        overflow: 'hidden',
        boxShadow: 'none',
        mr: '1px',
      }}
    >
      <CardMedia
        component="img"
        image={event.cover.url}
        alt={event.title}
        sx={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          imageRendering: 'optimizeQuality',
        }}
      />
      {openInfo && (
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Overlay to ensure text readability
            height: '100%',
            px: 4,
          }}
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <Iconify icon="line-md:email-opened-twotone" height={25} width={25} />
          <Typography variant="h5">By Invite Only</Typography>
          <Typography textAlign="center" variant="caption">
            This event is exclusive to users who have received an invite by email.
            <br /> Please refer to the email to access the event.
          </Typography>
        </Stack>
      )}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.2)', // Overlay to ensure text readability
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          // p: 2,
          color: 'white',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <Box display="flex" gap={1}>
            {event.participants.length > 0 && <>{renderAvatar}</>}
            {event?.organization?.logo && (
              <Avatar
                src={event?.organization?.logo?.url ?? ''}
                sx={{
                  width: 32,
                  height: 32,
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: '8px',
              color: 'black',
              padding: '4px 10px',
              textAlign: 'center',
            }}
          >
            <Typography fontWeight="bold" sx={{ fontSize: '18px' }}>
              {date}
            </Typography>
            <Typography sx={{ fontSize: '10px' }}>{month}</Typography>
          </Box>
        </Box>
        <CardContent
          sx={{
            p: 0,
            px: 2,
            pt: 2,
            pb: 1,
            textAlign: 'left',
            mb: '-3px',
            height: '29%',
            ...bgGradient({
              startColor: `${alpha('#212121', 0.01)} 0%`,
              endColor: `${alpha('#09090B', 1)} 55%`,
            }),
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
            {event.title}
          </Typography>
          <Stack direction="row" justifyContent="space-between" mt={0.5}>
            <Stack
              direction="row"
              gap={0.5}
              alignItems="center"
              sx={{ color: 'rgba(255, 255, 255, 0.8)' }}
            >
              <Typography variant="subtitle2">{event.venue.name}</Typography>
            </Stack>
            {event && <EventStatus event={event} eventDate={new Date(event?.endDate || '')} />}
          </Stack>
        </CardContent>
      </Box>
    </Card>
  );
}
