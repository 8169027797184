import { useQuery } from '@tanstack/react-query';
import { CancelToken } from 'axios';
import { useMemo } from 'react';
import useSWR from 'swr';
// utils
import axios, { fetcher, endpoints, axiosInstance } from 'src/utils/axios';
// types
import { isValidToken } from 'src/auth/context/jwt/utils';
import { AuthUserType } from 'src/auth/types';
import { ICreateEvent, IEvent, ISubmitEvent } from 'src/types/events';

// ----------------------------------------------------------------------
const STORAGE_KEY = 'accessToken';

export async function createEvent(eventData: ICreateEvent) {
  /**
   * Work on server
   */
  const URL = endpoints.event.create;

  const { data } = await axios.post(URL, eventData);

  return { newEvent: data };
}

export async function submitEvent(eventData: ISubmitEvent) {
  /**
   * Work on server
   */
  const URL = endpoints.event.submit;

  const { data } = await axios.post(URL, eventData);

  return { newEvent: data };
}

export async function updateEvent(eventId: string, eventData: any) {
  /**
   * Work on server
   */
  const URL = endpoints.event.update(eventId);

  const data = await axios.post(URL, eventData);
  return { newEvent: data };
}

// ----------------------------------------------------------------------

export function useUpdateEvent(eventId: string, eventData: any) {
  const URL = endpoints.event.update(eventId);
  // @ts-ignore
  const { data, trigger, isLoading, error, isValidating } = useSWR(URL, fetcher);

  return { data, trigger };
}

// ----------------------------------------------------------------------

export async function searchEvents(query: any, cancelToken: CancelToken) {
  return axios.get(endpoints.event.list, { params: query, cancelToken });
}
export function useGetEvents(query: any) {
  const URL = query ? [endpoints.event.list, { params: { ...query } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      events: (data?.results as IEvent[]) || [],
      eventsLoading: isLoading,
      eventsError: error,
      eventsValidating: isValidating,
      eventsEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetLiveEvents(query: any) {
  const URL = query ? [endpoints.event.live, { params: { ...query } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      liveEvents: (data?.results as IEvent[]) || [],
      liveEventsLoading: isLoading,
      liveEventsError: error,
      liveEventsValidating: isValidating,
      liveEventsEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetAttendedEvents() {
  const { data, isLoading, error, isValidating } = useSWR(endpoints.event.attended, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      events: (data?.results as IEvent[]) || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetSubscribedEvents() {
  const { data, isLoading, error, isValidating } = useSWR<IEvent[]>(
    endpoints.event.subscribedEvents,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const memoizedValue = useMemo(
    () => ({
      events: data || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetEvent(id: string, user?: AuthUserType, disable?: boolean) {
  // Determine the URL based on auth status
  const getEventUrl = () => {
    if (typeof window !== 'undefined') {
      const accessToken = localStorage.getItem(STORAGE_KEY);
      return accessToken && isValidToken(accessToken)
        ? endpoints.event.detail(id)
        : endpoints.event.detailPublic(id);
    }
    return endpoints.event.detailPublic(id);
  };

  const fetchEvent = async () => {
    const response = await axios.get<IEvent>(getEventUrl());
    return response.data;
  };

  return useQuery({
    queryKey: ['event', id, user?.id],
    queryFn: fetchEvent,
    retry: true,
    enabled: disable,
  });
}

export async function getEvents(query: any): Promise<IEvent[]> {
  const response = query
    ? await axiosInstance.get<IEvent[]>(endpoints.event.list, { params: { ...query } })
    : await axiosInstance.get<IEvent[]>(endpoints.event.list);

  return response.data;
}
